.p-tree {
  padding: 0.5rem;
}

.p-treeselect:hover {
  border-color: rgb(156, 163, 175) !important;
}

.p-treeselect-label {
  padding: 0.25rem !important;
  padding-left: 0.75rem !important;
}

.dropdown-disabled {
  background-color: hsl(0, 0%, 95%);
  opacity: 1 !important;
}
.p-treeselect {
  border: 1px solid rgb(156, 163, 175); /* matches tailwind border-gray-400 */
  border-radius: 4px;
  border-collapse: collapse;
  height: 34px;
}

.p-treenode-content {
  padding: 0.125rem !important;
}

.p-tree-toggler {
  margin-right: 0px !important;
  align-self: start;
  /* transition: none !important; */
}

.p-link:focus {
  box-shadow: none !important;
}

.p-treeselect-panel .p-treeselect-header {
  padding: 0.5rem;
  background-color: #fff;
  border: none;
}

.p-inputtext {
  padding: 0.5rem;
}
