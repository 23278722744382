scrollbar-show::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

scrollbar-show::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
