.react-datepicker__day--today {
  color: black !important;
  background-color: white !important;
  border: 2px solid #bfdbfe !important;
  border-radius: 0.25rem !important;
  font-weight: normal !important;
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
  color: black !important;
  background-color: #bfdbfe !important;
  font-weight: bold !important;
}

.react-datepicker__input-container {
  height: 100%;
}
