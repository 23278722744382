/* Overlay elements in a CSS grid so both grow to fill available height.  */
.wrapper {
  --textarea-left-padding: 0.5rem;
  display: grid;
  @apply w-full;
}

/* Hidden element to size with textarea content. */
.wrapper::after {
  content: attr(data-replicated-value) ' ';
  /* Imitate textarea. */
  white-space: pre-wrap;
  /* Hide from user, screen readers, etc. */
  visibility: hidden;
}

.wrapper > textarea {
  /* Disable user resizing. */
  resize: none;
  /* Hide scrollbar on resizing. */
  overflow: hidden;
  /* Repeated here for specificity to override tailwind-forms */
  @apply border border-gray-300 focus:border-blue-600 w-full min-w-0 rounded break-words;
  font-size: var(--textarea-font-size);
  line-height: var(--textarea-line-height);
}

.wrapper > textarea,
.wrapper::after {
  /* Style both elements the same */
  padding: 0.5rem;
  padding-left: var(--textarea-left-padding);
  /* Repeated here for specificity to override tailwind-forms */
  @apply border border-gray-300 focus:border-blue-600 w-full min-w-0 rounded break-words;
  /* Place in same grid cell */
  grid-area: 1 / 1 / 2 / 2;
  font-size: var(--textarea-font-size);
  line-height: var(--textarea-line-height);
}
